import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import HeroBackgroundImage from 'design/components/HeroBackgroundImage/HeroBackgroundImage'
import {
  Carousel,
  Box,
  rem,
  theme,
  Text,
  Lockup,
  ButtonLink,
} from '@butcherbox/freezer'
import data, { CareersData } from './Careers.data'
import * as Styles from './Careers.css'

function Careers() {
  const { heroImageDesktop, heroImageMobile } = useStaticQuery(graphql`
    query {
      heroImageDesktop: file(
        relativePath: { eq: "butcherbox-team-desktop.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroImageMobile: file(
        relativePath: { eq: "butcherbox-team-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources: FluidObject[] = [
    { ...heroImageMobile.childImageSharp.fluid, media: '(max-width: 499px)' },
    { ...heroImageDesktop.childImageSharp.fluid },
  ]

  /* Make icon element with props */
  const getIcon = (icon: CareersData['benefits']['list'][0]['icon']) => {
    const Icon = icon
    return <Icon marginBottom={16} mx={0} size="regular" variant="duo" />
  }

  const HeroContentStyle = {
    paddingTop: { base: rem(32), tablet: rem(198) },
    paddingBottom: { base: rem(64), tablet: rem(80) },
    paddingX: { base: rem(24), tablet: 0 },
  }

  return (
    <UnauthenticatedLayout>
      {/* 
        The design of the module overlap for the quote section 
        with 100vw can cause a horizontal scroll, this mitigates
        that specifically on this page
      */}
      <Helmet>
        <style>{`body { overflow-x: hidden;}`}</style>
      </Helmet>
      <HeroBackgroundImage
        aria-label="ButcherBox Careers"
        contentStyle={HeroContentStyle}
        fluid={sources}
        overlayFill={theme.color.scrim50pct}
      >
        <SectionHeadline color="inherit" component="h1">
          {data.hero.headline}
        </SectionHeadline>

        <Box className={Styles.HeroSubhead}>
          <Text color="inherit" component="p" variant="H2Bold">
            {data.hero.subhead}
          </Text>
        </Box>

        <Box className={Styles.ButtonResponsive}>
          <ButtonLink
            href={data.hero.cta.url}
            size="fluid"
            target="_self"
            variant="primary"
          >
            {data.hero.cta.text}
          </ButtonLink>
        </Box>
      </HeroBackgroundImage>

      <Box background="openSea" component="section" id="OurValues">
        <Box className={Styles.SectionContainer}>
          <SectionHeadline
            color="white"
            component="h2"
            textAlign={{ mobile: 'left', tablet: 'center' }}
          >
            {data.values.headline}
          </SectionHeadline>
          <SectionSubhead
            color="white"
            component="h3"
            textAlign={{ mobile: 'left', tablet: 'center' }}
          >
            {data.values.subhead}
          </SectionSubhead>
          <Box className={Styles.ValuesGrid}>
            {data.values.list.map((value, index) => (
              <div key={index}>
                <Text color="white" component="h4" variant="H2Bold">
                  {value.title}
                </Text>
                <Text color="white" component="p" variant="H2Regular">
                  {value.copy}
                </Text>
              </div>
            ))}
          </Box>
          <Box className={Styles.QuoteBoxWrap}>
            <Box className={Styles.QuoteBox}>
              <Carousel label="Quotes about values from ButcherBox Employees.">
                {data.values.quotes.map((quote, index) => (
                  <Box component="li" key={index} w="100%">
                    <Box className={Styles.CarouselItem}>
                      <Box className={Styles.CarouselTextWrap}>
                        <Text component="blockquote" variant="H3Regular">
                          {quote.copy}
                        </Text>
                      </Box>
                      <figcaption>
                        <Lockup>
                          <Text component="p" variant="H3Bold">
                            {quote.name}
                          </Text>
                          <Text
                            color="granite"
                            component="p"
                            textAlign="center"
                            variant="H3Regular"
                          >
                            {quote.title}
                          </Text>
                        </Lockup>
                      </figcaption>
                    </Box>
                  </Box>
                ))}
              </Carousel>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box component="section" id="BenefitsAndPerks">
        <Box className={Styles.SectionContainer}>
          <SectionHeadline>{data.benefits.headline}</SectionHeadline>
          <SectionSubhead>{data.benefits.subhead}</SectionSubhead>
          <Box className={Styles.ValuesGrid}>
            {data.benefits.list.map((benefit, index) => (
              <div key={index}>
                {getIcon(benefit.icon)}
                <Text component="h4" variant="H2Bold">
                  {benefit.title}
                </Text>
                <Text component="p" variant="H2Regular">
                  {benefit.copy}
                </Text>
              </div>
            ))}
          </Box>
        </Box>
      </Box>

      <Box background="openSea" component="section" id="Footer">
        <Box className={Styles.SectionContainer}>
          <SectionHeadline color="white" textAlign="center">
            {data.footer.headline}
          </SectionHeadline>
          <SectionSubhead color="white" textAlign="center">
            {data.footer.subhead}
          </SectionSubhead>
          <Box className={Styles.ButtonResponsive}>
            <ButtonLink
              component="a"
              href={data.footer.cta.url}
              size="fluid"
              target="_self"
              variant="primary"
            >
              {data.footer.cta.text}
            </ButtonLink>
          </Box>
        </Box>
      </Box>
    </UnauthenticatedLayout>
  )
}

const SectionHeadline = ({ children, ...props }) => {
  return (
    <Box className={Styles.SectionHeadline}>
      <Text component="h2" variant="DisplayOne" {...props}>
        {children}
      </Text>
    </Box>
  )
}

const SectionSubhead = ({ children, ...props }) => {
  return (
    <Box className={Styles.SectionSubhead}>
      <Text component="h3" variant="H2Regular" {...props}>
        {children}
      </Text>
    </Box>
  )
}

export default Careers
