import { rem, styled } from 'design'
import Image from 'gatsby-image'
import type { HeroBGImageStyleProps } from 'design/components/HeroBackgroundImage//HeroBackgroundImage.types'
import { Box } from '@chakra-ui/core'

export const Section = styled.section`
  color: black;
  position: relative;
  ${(p) => p.theme.media.tablet} {
    color: white;
  }
`

/**
 * !important - is needed here to oveeride the inline styles injected by
 * gatsby-image the position overrides are needed to turn gatsby-image (which is intended for inline usage)
 * to be used as an object-fit background image.
 */
export const BGImage = styled(Image)<HeroBGImageStyleProps>`
  position: relative !important;
  width: 100%;
  height: auto;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: transparent;
  }

  ${(p) => p.theme.media.tablet} {
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    object-fit: cover !important;
    object-position: center center !important;
    & > picture > img {
      object-fit: cover !important;
      object-position: center center !important;
    }
    &:before {
      background: ${(p) => (p.overlayFill ? p.overlayFill : 'transparent')};
    }
  }
`
export const Content = styled(Box)`
  width: 100%;
  color: ${(p) => p.theme.colors.bb.slate};
  max-width: ${rem(1008)};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 2;
  ${(p) => p.theme.media.tablet} {
    color: white;
    text-align: center;
    align-items: center;
  }
`
