import React from 'react'
import * as Styled from 'design/components/HeroBackgroundImage/HeroBackgroundImage.style'
import { HeroBackgroundImageProps } from 'design/components/HeroBackgroundImage/HeroBackgroundImage.types'

/**
 * This Hero renders a background image with content overlaid in the center of it on desktop.
 * On mobile, the photo is positioned above the content.
 */
function HeroBackgroundImage({
  children,
  fluid,
  overlayFill,
  contentStyle = undefined,
  ...rest
}: HeroBackgroundImageProps) {
  return (
    <Styled.Section {...rest}>
      <Styled.BGImage
        fluid={fluid}
        imgStyle={{ objectFit: 'cover' }}
        overlayFill={overlayFill}
      />
      <Styled.Content {...contentStyle}>{children}</Styled.Content>
    </Styled.Section>
  )
}

export default HeroBackgroundImage
