import {
  IconBacon,
  IconFlexibleWork,
  IconCommuter,
  IconPTO,
  IconInvest,
  IconWellness,
  IconInsurance,
} from '@butcherbox/freezer'

type cta = {
  text: string
  url: string
}

type careersHero = {
  headline: string
  subhead: string
  cta: cta
}

interface listBase {
  title: string
  copy: string
}

interface sectionBase {
  headline: string
  subhead: string
}

interface sectionWithList extends sectionBase {
  list: Array<listBase>
}

interface valuesSection extends sectionWithList {
  quotes: Array<{
    copy: string
    name: string
    title: string
  }>
}

interface benifitsSection extends sectionBase {
  list: Array<listBase & { icon: typeof IconInvest }>
}

export interface CareersData {
  hero: careersHero
  values: valuesSection
  benefits: benifitsSection
  footer: careersHero
}

const data: CareersData = {
  hero: {
    headline: 'Join Our Team',
    subhead:
      "We're a diverse team working together to challenge and support one another to bring about positive change in the world.",
    cta: {
      text: 'View Open Roles',
      url: 'https://jobs.lever.co/butcherbox',
    },
  },
  values: {
    headline: 'Our Values',
    subhead:
      'We’re in pursuit of a better way, one that’s focused on animal welfare, supporting farmers, treating our planet with respect, and upholding diversity, equity, and inclusion across our business.',
    list: [
      {
        title: 'Humble',
        copy: 'Never being too proud or having a “better than” attitude.',
      },
      {
        title: 'Accountable',
        copy:
          'Being reliable and responsible. Following through when you say you’ll do something.',
      },
      {
        title: 'Member Obsessed',
        copy:
          'Always keeping our members as the top priority in the decision-making process.  ',
      },
      {
        title: 'Authentic',
        copy:
          'Bringing your best, true self. Never feeling like you must be a certain way to please others.',
      },
      {
        title: 'Relentless Improvement',
        copy:
          'Constantly improving what we do in the spirit of being better while also realizing the work to improve is never truly done.',
      },
    ],
    quotes: [
      {
        copy:
          'To be accountable means to fulfill my obligations to my colleagues. No matter how big or small the task is, if I say I will do something, accountability means someone can trust that I’ll do it and give it 100%. I like to be that person others can turn to for help.',
        name: 'Anthony D.',
        title: 'Assistant Manager, Accounting',
      },
      {
        copy:
          "To me, being humble means being open to other people's ideas, input and feedback. It's knowing what I don't know and seeking out people who can fill in the gaps in my expertise. I think humility is the key to successful collaboration.",
        name: 'Callie D.',
        title: 'Head of Member Experience',
      },
    ],
  },
  benefits: {
    headline: 'Benefits & Perks',
    subhead:
      'We’re committed to providing the ButcherBox team with benefits and perks that enhance their overall mental and physical well-being both today and in the future.',
    list: [
      {
        icon: IconInsurance,
        title: 'Health',
        copy:
          'Our health benefits include medical, dental, vision, and life insurance, an employer-funded HSA, and short & long-term disability benefits.',
      },
      {
        icon: IconInvest,
        title: 'Financial',
        copy:
          'Our generous 401k match is available the day you start. We also offer an annual discretionary performance bonus and equity ownership in the company.',
      },
      {
        icon: IconPTO,
        title: 'Paid Time Off',
        copy:
          'Our unlimited PTO allows you to take the time you need have the work life balance that is right for you. We also offer 100% paid parental leave. We believe our best work is done when we have time to recharge.',
      },
      {
        icon: IconWellness,
        title: 'Wellness',
        copy:
          'We offer wellness benefits to both encourage and empower each employee to take the necessary time and action to care for themselves first. This includes fitness reimbursement, mental health counseling sessions, and a variety of other avenues of support for an employee’s whole self. ',
      },
      {
        icon: IconBacon,
        title: 'Free Meat',
        copy:
          'When you join ButcherBox you become a member in more ways than one. Each employee gets a free monthly shipment of our custom box. That means you pick the cuts you want, the type of meat you want, and it’s 100% on us. And if you’re not a meat eater, you can ship your box to friends or family, and switch it up as often as you wish.',
      },
      {
        icon: IconFlexibleWork,
        title: 'Remote-Friendly, Flexible Working',
        copy:
          'ButcherBox is a flexible workplace. We have three physical locations (Two in Massachusetts, one in Oklahoma), and we have associates working from home all across the country. If you want to come in 5 days, so be it. Be home full-time, all good. Something in the middle? Up to you.',
      },
      {
        icon: IconCommuter,
        title: 'Commuter Benefits',
        copy:
          'When you do come to the office we’d like to make it a bit easier. Whether you arrive by bike, car, or rail, ButcherBox will have you covered. We subsidize 70% of MBTA/commuter rail costs or provide a $20 voucher each month for cyclists.',
      },
    ],
  },
  footer: {
    headline: 'Like what you see?',
    subhead:
      'There are open spots at our table! Explore your next opportunity and apply to join the team.',
    cta: {
      text: 'View Open Roles',
      url: 'https://jobs.lever.co/butcherbox',
    },
  },
}

export default data
